
import { defineComponent, toRefs, Ref } from 'vue';
import {mapState} from 'vuex';

import {useThreadsDetail, usePostsList} from '@/compositions/bbs';
import BreadCrumbs from '@/components/widgets/bread-crumbs.vue';
import PostCreateModal from '@/components/modals/bbs-post-create.vue';
import Pagination from '@/components/widgets/pagination.vue';
import {dateFormat} from '@/utils/datetime';

export default defineComponent({

    components: {
        BreadCrumbs,
        PostCreateModal,
        Pagination
    },

    props: {
        id: Number,
        page: Number
    },

    setup: (props) => {

        const {id, page} = toRefs(props);
        const threadDetailSetup = useThreadsDetail(id as Ref<number>);
        const postsListSetup = usePostsList(id as Ref<number>, page as Ref<number>);

        return {
            ...threadDetailSetup,
            ...postsListSetup
        };
    },

    data: function () {
        return {
            showPostCreateModal: false,
            replyPid: 0, // 父级postid
            replyId: 0 // 回复针对的具体postid
        };
    },

    methods: {
        createPost: function (thread, post) {
            this.showPostCreateModal = true;

            if (post) {
                this.replyPid = post.pid || post.id;
                this.replyId = post.id;
            }
            else {
                this.replyPid = 0;
                this.replyId = 0;
            }
        },

        onCreatePostSuccess: function () {
            window.location.reload();
        },

        dateFormat: dateFormat
    }
});
