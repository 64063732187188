
import {defineComponent} from 'vue';
import {createPostApi} from "@/api/bbs";

import Upload from '../widgets/upload.vue';

export default defineComponent({

    components: {
        Upload
    },

    props: {
        tid: {
            type: Number,
            required: true
        },
        fid: {
            type: Number,
            required: true
        },
        pid: {
            type: Number
        },
        rid: {
            type: Number
        }
    },

    data: function () {
        return {
            message: '',
            images: []
        };
    },

    methods: {

        close: function () {
            this.$emit('close');
        },

        submit: async function () {

            let res = await createPostApi({
                fid: this.fid,
                tid: this.tid,
                pid: this.pid,
                rid: this.rid,
                message: this.message,
                images: this.images.length ? this.images : null
            });

            if (res) {
                this.close();
                this.$emit('success');
            }
        },

        uploadSuccess: function (attachment) {
            this.images.push(attachment.url);
        },

        removeImage: function (index) {
            this.images.splice(index, 1);
        }
    }
});
