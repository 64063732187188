<template>
    <div class="container detail page-main-content">

        <bread-crumbs />

        <div class="topic-pager clearfix"></div>
        <div class="topic-title">
            <h1>{{thread.subject}}</h1>

            <!-- <i class="shoucang"></i> -->

            <!-- <a class="only-author">只看楼主</a> -->
            <a class="only-author" @click="createPost(thread)">回复</a>
        </div>

        <div class="topic-info" v-if="page === 1">
            <div class="com-topic-detail clearfix">
                <div class="detail-left">
                    <div class="com-author">
                        <div class="img-wrap">
                            <img :src="thread.author.avatar" />
                            <i class="louzhu-tip "></i>
                        </div>
                        <p class="name">{{thread.author_name}}</p>
                        <div class="statis">
                            <div>
                                <p class="p1">0</p>
                                <p class="p2">粉丝</p>
                            </div>
                            <div>
                                <p class="p1">1</p>
                                <p class="p2">帖子</p>
                            </div>
                            <div>
                                <p class="p1">0</p>
                                <p class="p2">回复</p>
                            </div>
                        </div>
                        <div class="address">
                            <p>注册：{{dateFormat(thread.author.add_time)}}</p>
                            <!-- <p>来自：北京市</p> -->
                        </div>
                    </div>
                </div>
                <div class="detail-right">
                    <div class="top-bar clearfix">
                        <div class="publish-time">发表于&nbsp;{{dateFormat(thread.add_time)}}</div>

                        <!-- <div class="share"><span class="label">分享：</span>
                            <div class="inner">
                                <div class="bdsharebuttonbox"><a class="bds_tsina" title="分享到新浪微博"></a><a class="bds_qzone" title="分享到QQ空间"></a><a class="bds_weixin" title="分享到微信"></a></div>
                            </div>
                        </div> -->

                        <div class="statis">回复：<span class="red">{{total}}</span></div>
                    </div>
                    <div class="content">
                        <div class="pre">
                            <div class="topic-detail">{{thread.message}}</div>
                        </div>

                        <div class="content-img" v-if="thread.images && thread.images.length">
                            <p class="img-wrap" v-for="(image, index) in thread.images" :key="index">
                                <img :src="image">
                            </p>
                        </div>
                    </div>
                    <div class="button-bar"></div>
                    <div class="detail-footer">
                        <div class="zan-wrap"></div>
                    </div>
                    <div class="detail-relative-wrap"></div>
                    <div class="bottom-bar clearfix"></div>
                </div>
            </div>
        </div>

        <div class="comment-info">
            <div class="com-topic-comment clearfix">
                <ul>

                    <li v-for="(post, index) in posts" :key="post.id">
                        <div class="fg"></div>
                        <div class="detail-left">
                            <div class="com-author">
                                <div class="img-wrap">
                                    <img :src="post.author.avatar" />
                                    <i v-if="post.author_id === thread.author_id" class="louzhu-tip"></i>
                                </div>
                                <p class="name">{{post.author_name}}</p>
                                <div class="statis">
                                    <div>
                                        <p class="p1">0</p>
                                        <p class="p2">粉丝</p>
                                    </div>
                                    <div>
                                        <p class="p1">3</p>
                                        <p class="p2">帖子</p>
                                    </div>
                                    <div>
                                        <p class="p1">8</p>
                                        <p class="p2">回复</p>
                                    </div>
                                </div>
                                <div class="address">
                                    <p>注册：{{dateFormat(post.author.add_time)}}</p>
                                    <!-- <p>来自：</p> -->
                                </div>
                            </div>
                        </div>
                        <div class="detail-right">
                            <div class="top-bar clearfix">
                                <div class="publish-time">发表于&nbsp;{{dateFormat(post.add_time)}}</div>
                                <div class="footer-name">{{index === 0 ? '沙发' : (index * page + 1) + '楼'}}</div>
                            </div>
                            <div class="content">
                                <p class="content-text">{{post.message}}</p>

                                <div class="content-img" v-if="post.images && post.images.length">
                                    <p class="img-wrap" v-for="(image, index) in post.images" :key="index">
                                        <img :src="image">
                                    </p>
                                </div>
                            </div>

                            <div class="content quote-wrap" v-if="post.replies && post.replies.length">

                                <div class="comment-quote" v-for="reply in post.replies" :key="reply.id">
                                    【{{reply.author_name}}】<span v-if="reply.reply_name">回复【{{reply.reply_name}}】</span>: {{reply.message}}

                                    <div class="publish-time">
                                        {{dateFormat(reply.add_time)}} <a @click="createPost(thread, reply)">回复</a>
                                    </div>
                                </div>

                            </div>

                            <div class="bottom-bar clearfix">
                                <a class="right" @click="createPost(thread, post)">回复</a>
                            </div>
                        </div>
                    </li>

                </ul>
            </div>
        </div>

        <div class="topic-pager bottom clearfix">
            <div class="left">
                <pagination :total="total"
                        :page="page" />
            </div>
        </div>

        <!-- <div class="">
            <div class="com-detail-footer-items">

                <div class="com-side-com-list-col3 com-part">
                    <h3 class="part-title">热门推荐</h3>
                    <ul class="list-w clearfix">
                        <li>
                            <a class="name">
                                <span class="dian">•</span>科三考不过啊，练车把把过，一到考试就垮掉了！
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="com-jiaxiao-list-random com-part">
                    <h3 class="part-title">还没报驾校？看看大家报的驾校</h3>
                    <ul class="list-w clearfix">
                        <li><a class="col col1"><span class="dian">•</span>天成驾校</a><span class="col col2">C1/C2</span>
                            <div class="col col2 star-w-s">
                                <div class="bfb" style="width: 90%"></div>
                            </div><span class="col col4">500元</span>
                        </li>
                    </ul>
                </div>

                <div class="com-side-com-list-col3 com-part">
                    <h3 class="part-title">相关模拟考试</h3>
                    <ul class="list-w clearfix">
                        <li><a class="name"><span class="dian">•</span>恩运驾校考试题库</a></li>
                    </ul>
                </div>

                <div class="com-side-com-list-col3 com-part">
                    <h3 class="part-title">热门问答</h3>
                    <ul class="list-w clearfix">
                        <li><a class="name"><span class="dian">•</span>机加零件如何防止磕碰</a></li>
                    </ul>
                </div>

            </div>
        </div> -->

        <teleport to="body">
            <post-create-modal v-if="showPostCreateModal"
                    :tid="id"
                    :fid="thread.fid"
                    :pid="replyPid"
                    :rid="replyId"
                    @close="showPostCreateModal = false"
                    @success="onCreatePostSuccess" />
        </teleport>
    </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, Ref } from 'vue';
import {mapState} from 'vuex';

import {useThreadsDetail, usePostsList} from '@/compositions/bbs';
import BreadCrumbs from '@/components/widgets/bread-crumbs.vue';
import PostCreateModal from '@/components/modals/bbs-post-create.vue';
import Pagination from '@/components/widgets/pagination.vue';
import {dateFormat} from '@/utils/datetime';

export default defineComponent({

    components: {
        BreadCrumbs,
        PostCreateModal,
        Pagination
    },

    props: {
        id: Number,
        page: Number
    },

    setup: (props) => {

        const {id, page} = toRefs(props);
        const threadDetailSetup = useThreadsDetail(id as Ref<number>);
        const postsListSetup = usePostsList(id as Ref<number>, page as Ref<number>);

        return {
            ...threadDetailSetup,
            ...postsListSetup
        };
    },

    data: function () {
        return {
            showPostCreateModal: false,
            replyPid: 0, // 父级postid
            replyId: 0 // 回复针对的具体postid
        };
    },

    methods: {
        createPost: function (thread, post) {
            this.showPostCreateModal = true;

            if (post) {
                this.replyPid = post.pid || post.id;
                this.replyId = post.id;
            }
            else {
                this.replyPid = 0;
                this.replyId = 0;
            }
        },

        onCreatePostSuccess: function () {
            window.location.reload();
        },

        dateFormat: dateFormat
    }
});
</script>

<style>

.publish-time {
    text-align: right;
    color: #999;
    font-size: 12px;
}

.header-background-gray {
    color: #f5f5f5
}

.block-item>.more-btn,
.load-more-btn {
    display: block;
    height: .8rem;
    line-height: .8rem;
    font-size: 18px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #f5f5f5;
    text-align: center;
    color: #666
}

.t-a-c {
    text-align: center
}

.com-topic-detail>.detail-right>.bottom-bar,
.com-topic-detail>.detail-right>.content .pre,
.com-topic-detail>.detail-right>.top-bar {
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-topic-detail,
.com-topic-detail>.detail-right>.bottom-bar>.huifu+.huifu {
    border-right: 1px solid #e9e9e9
}

.com-topic-detail {
    border-left: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    background-color: #f5f5f5
}

.com-topic-detail>.detail-left {
    float: left;
    width: 220px;
    padding: 30px 0 10px
}

.com-topic-detail>.detail-right {
    margin-left: 220px;
    padding: 0 20px;
    background-color: #fff
}

.com-topic-detail>.detail-right>.top-bar {
    height: 40px;
    line-height: 40px;
    color: #999;
    font-size: 12px
}

.com-topic-detail>.detail-right>.top-bar>.publish-time {
    float: left
}

.com-topic-detail>.detail-right>.top-bar>.share {
    float: left;
    margin-left: 30px
}

.com-topic-detail>.detail-right>.top-bar>.share .inner {
    display: inline-block;
    margin-top: -3px;
    vertical-align: middle
}

.com-topic-detail>.detail-right>.top-bar>.statis {
    float: right
}

.com-topic-detail>.detail-right>.content {
    min-height: 100px;
    padding-top: 10px;
    line-height: 1.5
}

.com-topic-detail>.detail-right>.content p {
    line-height: 28px
}

.com-topic-detail>.detail-right>.content .pre {
    word-wrap: break-word;
    font-size: 18px
}

.com-topic-detail>.detail-right>.content pre.pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap
}

.com-topic-detail>.detail-right>.content>.content-img .img-wrap {
    width: auto;
    max-width: 700px;
    margin: 10px auto;
    text-align: center
}

.com-topic-detail>.detail-right>.content img {
    margin: 10px auto;
    max-width: 700px
}

.com-topic-detail>.detail-right>.button-bar {
    padding: 50px 0 0;
    text-align: center
}

.com-topic-detail>.detail-right>.button-bar>button {
    width: 150px;
    height: 40px;
    margin: 0 10px;
    color: #fff
}

.com-topic-detail>.detail-right>.button-bar>.zan {
    background-color: #7eb4ed
}

.com-topic-detail>.detail-right>.button-bar>.reply {
    background-color: #EE4653
}

.com-topic-detail>.detail-right>.detail-footer {
    padding: 10px 0 0;
    text-align: center;
    color: #999
}

.com-topic-detail>.detail-right>.detail-footer>.zan-wrap {
    display: inline-block;
    vertical-align: middle
}

.com-topic-detail>.detail-right>.detail-footer>.zan-wrap>.author-list {
    display: inline-block;
    max-width: 500px;
    vertical-align: middle;
    color: #7eb4ed;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1
}

.com-topic-detail>.detail-right>.detail-footer>.share {
    display: inline-block;
    vertical-align: middle;
    margin-left: 30px
}

.com-topic-detail>.detail-right>.detail-footer>.share .inner {
    display: inline-block;
    margin-top: -3px;
    vertical-align: middle
}

.com-topic-detail>.detail-right>.detail-relative-wrap {
    padding: 30px 0 10px
}

.com-topic-detail>.detail-right>.bottom-bar {
    padding: 10px 0 15px;
    font-size: 12px;
    line-height: 1
}

.com-topic-detail>.detail-right>.bottom-bar>.tuijian {
    float: left;
    color: #999
}

.com-topic-detail>.detail-right>.bottom-bar>.tuijian:hover {
    color: #EE4653
}

.com-topic-detail>.detail-right>.bottom-bar>.huifu {
    float: right;
    padding: 0 6px;
    cursor: pointer
}

.com-author>.address,
.com-author>.statis>div>.p2 {
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-author>.address>p,
.com-author>.name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.com-author {
    width: 150px;
    margin: 0 auto
}

.com-author>.img-wrap {
    position: relative;
    width: 100px;
    height: 100px;
    margin: auto;
    overflow: hidden
}

.com-author>.img-wrap>.louzhu-tip {
    position: absolute;
    top: 0;
    right: 0;
    width: 48px;
    height: 48px;
    background: url("../../assets/files/13f544edd312e3a4fafdabcb4b8029d5.png") right top no-repeat;
    background-size: 100%
}

.com-author>.img-wrap img {
    display: block;
    width: 100%;
    height: auto;
    border: 4px solid #fff
}

.com-author>.name {
    padding: 10px 0;
    text-align: center;
    font-weight: 700;
    -webkit-line-clamp: 1;
    line-clamp: 1
}

.com-author>.statis {
    padding: 10px 0
}

.com-author>.statis>div {
    display: inline-block;
    width: 33.3%;
    vertical-align: top;
    text-align: center
}

.com-author>.statis>div>.p1 {
    color: #EE4653;
    line-height: 1.5
}

.com-author>.statis>div>.p2 {
    color: #666;
    font-size: 14px
}

.com-author>.statis>div+div {
    border-left: 1px solid #999
}

.com-author>.address {
    padding-top: 5px;
    padding-left: 8px;
    color: #999;
    line-height: 1.5;
    font-size: 12px
}

.com-author>.address>p {
    -webkit-line-clamp: 1;
    line-clamp: 1
}

.com-author .bar {
    padding-top: 12px
}

.com-author .bar>button {
    display: inline-block;
    width: 100%;
    line-height: 38px;
    border: 1px solid #e9e9e9;
    background-color: #fff
}

.com-author .bar>button:hover {
    color: #EE4653
}

.com-topic-vote>.car-img-wrap {
    position: relative;
    margin: 30px auto 10px;
    max-width: 580px;
    text-align: center
}

.com-topic-vote>.car-img-wrap>.car {
    display: inline-block;
    margin: 0 40px;
    width: 210px;
    vertical-align: top
}

.com-topic-vote>.car-img-wrap>.car>.car-img {
    width: 210px;
    height: 140px;
    margin: 0;
    overflow: hidden;
    background-size: 100%
}

.com-topic-vote>.car-img-wrap>.car>.car-img .car-name {
    line-height: 1.5px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1
}

.com-topic-vote>.car-img-wrap>.car>.car-img.transform {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg)
}

.com-topic-vote>.car-img-wrap>.pk-icon {
    position: absolute;
    width: 80px;
    height: 90px;
    top: 50%;
    left: 50%;
    margin-top: -45px;
    margin-left: -40px;
    background: url("../../assets/files/04df8406d2efc5ccf104e3134fc5b8c7.png") no-repeat;
    background-size: 100%
}

.com-topic-vote>.car-img-wrap>.pk-icon3 {
    margin-top: -70px
}

.com-topic-vote>.statis {
    padding-top: 10px;
    color: #999;
    text-align: center
}

.com-topic-vote>.car-bfb-wrap {
    width: 500px;
    margin: auto
}

.com-topic-vote>.car-bfb-wrap>.row {
    height: 40px;
    line-height: 40px
}

.com-topic-vote>.car-bfb-wrap>.row>.col {
    display: inline-block;
    vertical-align: middle
}

.com-topic-vote>.car-bfb-wrap>.row>.col1 {
    width: 13%;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1
}

.com-topic-vote>.car-bfb-wrap>.row>.col2 {
    width: 65%;
    padding: 10px
}

.com-topic-vote>.car-bfb-wrap>.row>.col2 .tiao1 {
    width: 100%;
    height: 6px;
    background-color: #f5f5f5;
    border-radius: 3px
}

.com-topic-vote>.car-bfb-wrap>.row>.col2 .tiao2 {
    height: 100%;
    background-color: #EE4653;
    border-radius: 3px
}

.com-topic-vote>.car-bfb-wrap>.row>.col3 {
    width: 7%;
    text-align: center;
    color: #EE4653
}

.com-topic-vote>.car-bfb-wrap>.row>.col4 {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    background: url("../../assets/files/58d9eeace7a650f77dd383e47255390e.png") center no-repeat;
    background-size: 100%;
    cursor: pointer;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg)
}

.com-topic-vote>.car-bfb-wrap2 {
    width: 400px;
    margin: auto;
    padding-bottom: 10px
}

.com-topic-vote>.car-bfb-wrap2>.row>.col1 {
    float: left;
    width: 40px;
    height: 40px;
    background: url("../../assets/files/58d9eeace7a650f77dd383e47255390e.png") no-repeat;
    background-size: 100%;
    cursor: pointer
}

.com-topic-vote>.car-bfb-wrap2>.row>.col2 {
    position: relative;
    margin: 15px 48px 0
}

.com-topic-vote>.car-bfb-wrap2>.row>.col2>.bar1 {
    float: left;
    background-color: #EE4653;
    height: 8px;
    border-radius: 16px;
    margin-left: -1px
}

.com-topic-vote>.car-bfb-wrap2>.row>.col2>.bar2 {
    float: right;
    background-color: #7eb4ed;
    height: 8px;
    border-radius: 16px;
    margin-right: -1px
}

.com-topic-vote>.car-bfb-wrap2>.row>.col2>.bfb-count1 {
    position: absolute;
    left: 0;
    bottom: -6px;
    color: #EE4653
}

.com-topic-vote>.car-bfb-wrap2>.row>.col2>.bfb-count2 {
    position: absolute;
    right: 0;
    bottom: -6px;
    color: #7eb4ed
}

.com-topic-vote>.car-bfb-wrap2>.row>.col3 {
    float: right;
    width: 40px;
    height: 40px;
    background: url("../../assets/files/c16b130dea2edb8a6ca62a22efc35d91.png") no-repeat;
    background-size: 100%;
    cursor: pointer
}

.com-topic-help>.tags-wrap>.budget-tags,
.com-topic-help>.tags-wrap>label {
    vertical-align: top;
    display: inline-block
}

.com-topic-help>.tags-wrap {
    padding-top: 10px
}

.com-topic-help>.tags-wrap>label {
    width: 50px;
    color: #999
}

.com-topic-help>.tags-wrap>.need-tags {
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px
}

.com-topic-help>.tags-wrap>.need-tags>span {
    display: inline-block;
    line-height: 1.4;
    margin: 5px;
    padding: 0 5px;
    border: 1px solid;
    border-radius: 8px;
    color: #7eb4ed
}

.com-topic-video>.dialog-video-box>.content-box>.btn-bar>a,
.com-topic-video>.dialog-video-box>.content-box>.content-text {
    font-size: 18px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-topic-video {
    width: 580px;
    margin: 13px auto
}

.com-topic-video video {
    display: block;
    width: 100%;
    height: auto
}

.com-topic-video>.dialog-video-box {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .7);
    z-index: 1000
}

.com-topic-video>.dialog-video-box>.content-box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 325px;
    height: 171.6px;
    margin-top: -84.5px;
    margin-left: -162.5px;
    background: #fff;
    border-radius: 6.5px;
    padding: 13px 0
}

.com-topic-video>.dialog-video-box>.content-box>.content-text {
    line-height: 32.5px;
    border-bottom: 1.3px solid #e9e9e9;
    padding: 13px 13px 26px 52px
}

.com-topic-video>.dialog-video-box>.content-box>.btn-bar {
    text-align: center
}

.com-topic-video>.dialog-video-box>.content-box>.btn-bar>a {
    display: inline-block;
    line-height: 52px;
    width: 50%;
    text-align: center
}

.com-topic-video>.dialog-video-box>.content-box>.btn-bar>a:first-child {
    border-right: 1.3px solid #e9e9e9
}

.com-topic-video>.iphone-bg-wrap {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent
}

.com-topic-audio .audio-wrap {
    display: -webkit-box
}

.com-topic-audio .audio-wrap .voice-bg {
    display: block;
    width: 13px;
    height: 18px;
    background: url("../../assets/files/5a3ec94c5c563089587f5ebb44983c1d.png") left center no-repeat;
    background-size: 100% 100%
}

.com-topic-audio .audio-wrap .audio-bg {
    display: block;
    width: 93px;
    height: 28px;
    padding: 5px 0 0 12px;
    background-size: 13px 18px;
    background-color: #8cdffe;
    border-radius: 5px
}

.com-topic-audio .audio-wrap .audio-scd {
    display: block;
    line-height: 30px;
    color: #333;
    font-size: 14px;
    font-weight: 700;
    margin-left: 12px;
    font-family: microsoft yahei
}

@-webkit-keyframes zoomIn {
    0% {
        background: url("../../assets/files/9f6c57d097e320b65591edf29e2c083d.png") center center no-repeat;
        background-size: 100% 100%
    }

    50% {
        background: url("../../assets/files/2b7c181271a4a9db31594fc070e282b5.png") center center no-repeat;
        background-size: 100% 100%
    }

    100% {
        background: url("../../assets/files/5a3ec94c5c563089587f5ebb44983c1d.png") center center no-repeat;
        background-size: 100% 100%
    }
}

@keyframes zoomIn {
    0% {
        background: url("../../assets/files/9f6c57d097e320b65591edf29e2c083d.png") center center no-repeat;
        background-size: 100% 100%
    }

    50% {
        background: url("../../assets/files/2b7c181271a4a9db31594fc070e282b5.png") center center no-repeat;
        background-size: 100% 100%
    }

    100% {
        background: url("../../assets/files/5a3ec94c5c563089587f5ebb44983c1d.png") center center no-repeat;
        background-size: 100% 100%
    }
}

.com-topic-audio .zoomIn {
    -webkit-animation: zoomIn 1s infinite both;
    animation: zoomIn 1s infinite both
}

.com-topic-audio>.audio-wrap {
    margin-top: 20px;
    margin-bottom: 20px
}

.com-topic-audio>.audio-wrap>.audio-bg {
    height: 38px;
    cursor: pointer
}

.com-topic-audio>.audio-wrap>.audio-bg .voice-bg {
    margin: 5px 0 0 12px
}

.com-topic-audio>.audio-wrap>.audio-scd {
    line-height: 38px
}

.com-topic-work .work-container {
    height: 100%;
    position: relative;
    padding: 0
}

.com-topic-work .work-container ul {
    border: 1px solid #EEE
}

.com-topic-work .work-container li {
    display: -webkit-box;
    border-bottom: 1px solid #EEE
}

.com-topic-work .work-container li.first {
    padding: 9.28px 9.28px 9.28px 5.8px
}

.com-topic-work .work-container li.first a {
    display: -webkit-box;
    -webkit-box-flex: 1;
    color: #333;
    text-decoration: none
}

.com-topic-work .work-container li.first img {
    display: block;
    width: auto;
    height: 53px;
    margin: 0
}

.com-topic-work .work-container li.first .car {
    -webkit-box-flex: 1;
    font-size: 15.08px;
    line-height: 53.36px;
    padding-left: 17.4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5.8px
}

.com-topic-work .work-container li.first .arrow {
    width: 10.44px;
    background: url("../../assets/files/6a3eb8a10a1a27d7764874cfd4cbe819.png") right center no-repeat;
    background-size: 100%
}

.com-topic-work .work-container li div.title {
    color: #333;
    text-align: center;
    padding: 16.24px 13.92px;
    width: 25%;
    font-size: 16.24px;
    line-height: 20.88px;
    border-right: 1px solid #EEE;
    display: flex;
    align-items: center;
    justify-content: center
}

.com-topic-work .work-container li div.desc {
    color: #333;
    text-align: center;
    padding: 16.24px 13.92px;
    -webkit-box-flex: 1;
    font-size: 15.08px;
    line-height: 20.88px
}

.com-topic-work .work-container .content {
    margin-top: 17.4px
}

.com-topic-work .work-container .content header {
    font-size: 17.4px;
    line-height: 34.8px
}

.com-topic-work .work-container .content p {
    color: #999;
    font-size: 15.08px;
    line-height: 23.2px
}

.com-topic-work .work-container .content img {
    display: block;
    width: 100%;
    margin: 11.6px 0
}

.com-topic-work pre {
    white-space: pre-wrap;
    word-wrap: break-word
}

.com-detail-content-quote>.row {
    display: block;
    margin: 20px 0 10px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 4px
}

.com-detail-content-quote>.row>.img-w {
    display: inline-block;
    vertical-align: middle;
    width: 102px;
    height: 70px;
    overflow: hidden
}

.com-detail-content-quote>.row>.img-w img {
    display: block;
    width: 100%;
    height: auto;
    border: none;
    margin: 0 !important
}

.com-detail-content-quote>.row>.content-text {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
    width: 760px;
    line-height: 1.4;
    text-decoration: underline
}

.com-topic-koubei .kb-container {
    height: 100%;
    position: relative;
    padding: 0
}

.com-topic-koubei .kb-report-wrap {
    height: 243.6px;
    display: -webkit-box;
    position: relative;
    -webkit-box-align: end
}

.com-topic-koubei .kb-report {
    position: relative;
    height: 203px
}

.com-topic-koubei .kb-report .report-bg {
    position: absolute;
    height: 174px;
    width: 100%;
    top: 0;
    right: 0;
    left: 0
}

.com-topic-koubei .kb-report .report-bg div {
    margin-top: 34.8px;
    border-top: 1px solid rgba(0, 0, 0, .05)
}

.com-topic-koubei .kb-report .report-col {
    border-top: 1px solid rgba(0, 0, 0, .05);
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    height: 174px;
    display: -webkit-box;
    position: relative;
    -webkit-box-align: end
}

.com-topic-koubei .kb-report .report-col .col-item {
    position: relative;
    -webkit-box-flex: 1;
    margin: 0 25px;
    height: 65%;
    background: -webkit-linear-gradient(top, #7eb4ed 0, #7eb4ed 100%);
    border-radius: 6.96px 6.96px 0 0
}

.com-topic-koubei .kb-report .report-col .col-item span {
    position: absolute;
    top: -23.2px;
    left: -4.64px;
    color: rgba(0, 0, 0, .4);
    min-width: 23.2px;
    text-align: center
}

.com-topic-koubei .kb-report .report-label {
    display: -webkit-box
}

.com-topic-koubei .kb-report .report-label span {
    display: block;
    -webkit-box-flex: 1;
    text-align: center;
    font-size: 13.92px;
    color: rgba(0, 0, 0, .4);
    line-height: 17.4px;
    padding: 11.6px 0 0
}

.com-topic-koubei .kb-report .report-label span:last-child {
    -webkit-box-flex: .5
}

.com-topic-koubei .kb-car-series {
    height: 47.56px;
    line-height: 47.56px;
    padding: 0 17.4px;
    margin-top: 41.76px;
    border: 1px solid rgba(0, 0, 0, .05);
    font-size: 16.24px;
    color: #353D51;
    font-weight: 700
}

.com-topic-koubei .kb-car-series a {
    text-decoration: none;
    color: #666
}

.com-topic-koubei .kb-car-series p {
    line-height: 47px !important;
    padding-right: 18.56px;
    background: url("../../assets/files/d75e153a8c105438f64aea20e2245bfa.png") right center no-repeat;
    background-size: 10.44px 16.24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.com-topic-koubei .kb-car-info {
    padding: 0 17.4px;
    background: #F7F7F7;
    margin-top: 13.92px
}

.com-topic-koubei .kb-car-info p {
    display: -webkit-box;
    padding: 11.6px 0 10.44px;
    border-bottom: 1px solid rgba(0, 0, 0, .04)
}

.com-topic-koubei .kb-car-info p span {
    font-size: 16.24px;
    line-height: 23.2px;
    display: block;
    width: 50%;
    color: #666
}

.com-topic-koubei .kb-car-info p span.single {
    width: 100%
}

.com-topic-koubei .kb-car-info p span i {
    font-size: 13.92px
}

.com-topic-koubei .kb-car-comm {
    display: -webkit-box
}

.com-topic-koubei .kb-car-comm div {
    padding: 4.64px 20.88px 0 0
}

.com-topic-koubei .kb-car-comm div label {
    display: block;
    padding: 20.88px 0 0;
    font-size: 13.92px;
    line-height: 16.24px
}

.com-topic-koubei .kb-car-comm p {
    font-size: 17.4px;
    color: #666;
    line-height: 24.36px;
    word-break: break-all;
    -webkit-box-flex: 1
}

.com-topic-koubei .kb-car-merit {
    margin-top: 30.16px
}

.com-topic-koubei .kb-car-merit div label {
    background: url("../../assets/files/142379d94c3a4ddf1f22dfd3fc592ed5.png") top center no-repeat;
    background-size: 18.56px 18.56px;
    color: #FFAB49
}

.com-topic-koubei .kb-car-defect {
    padding: 16.24px 0 30.16px;
    border-bottom: 1px solid rgba(0, 0, 0, .05)
}

.com-topic-koubei .kb-car-defect div label {
    background: url("../../assets/files/22f96ddf4319d6de9e292cc95f1911c8.png") top center no-repeat;
    background-size: 18.56px 18.56px;
    color: #3DA7FF
}

.com-topic-koubei .kb-car-intro {
    margin-top: 27.84px
}

.com-topic-koubei .kb-car-intro .intro-hd label {
    font-size: 17.4px;
    color: #333;
    line-height: 23.2px;
    font-weight: 700
}

.com-topic-koubei .kb-car-intro>p {
    display: inline-block;
    vertical-align: top
}

.com-topic-koubei .kb-car-intro .intro-hd span {
    font-size: 16.24px;
    line-height: 23.2px;
    color: #999
}

.com-topic-koubei .kb-car-intro .intro-con {
    padding-left: 4.64px;
    font-size: 17.4px;
    color: #666;
    line-height: 24.36px
}

.com-topic-koubei .kb-img-list {
    overflow: hidden;
    width: 100%;
    margin-top: 27.84px;
    margin-bottom: 18.56px
}

.com-topic-koubei .kb-img-list img {
    display: block;
    width: 100%
}

.com-topic-koubei .kb-report {
    width: 500px;
    margin: 0 auto
}

.com-topic-comment>ul>li,
.com-topic-comment>ul>li>.detail-right>.bottom-bar>.huifu+.huifu {
    border-right: 1px solid #e9e9e9
}

.com-topic-comment>ul>li {
    border-left: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    background-color: #f5f5f5
}

.com-topic-comment>ul>li>.fg {
    height: 4px;
    background-color: #e9e9e9
}

.com-topic-comment>ul>li>.detail-left {
    float: left;
    width: 220px;
    padding: 30px 0 10px
}

.com-topic-comment>ul>li>.detail-right {
    position: relative;
    min-height: 290px;
    margin-left: 220px;
    padding: 0 20px 50px;
    background-color: #fff
}

.com-topic-comment>ul>li>.detail-right>.top-bar {
    height: 40px;
    line-height: 40px;
    color: #999;
    font-size: 12px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-topic-comment>ul>li>.detail-right>.content>.content-text,
.com-topic-comment>ul>li>.detail-right>.zuijia:before {
    font-size: 18px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-topic-comment>ul>li>.detail-right>.top-bar>.publish-time {
    float: left
}

.com-topic-comment>ul>li>.detail-right>.top-bar>.footer-name {
    float: right
}

.com-topic-comment>ul>li>.detail-right>.top-bar>.share {
    float: left;
    margin-left: 30px
}

.com-topic-comment>ul>li>.detail-right>.top-bar>.share .inner {
    display: inline-block;
    margin-top: -3px;
    vertical-align: middle
}

.com-topic-comment>ul>li>.detail-right>.top-bar>.statis {
    float: right
}

.com-topic-comment>ul>li>.detail-right>.content {
    padding-top: 10px;
    line-height: 1.5
}

.com-topic-comment>ul>li>.detail-right>.content p {
    line-height: 28px
}

.com-topic-comment>ul>li>.detail-right>.content>.content-img .img-wrap {
    width: auto;
    max-width: 700px;
    margin: 10px auto;
    text-align: center
}

.com-topic-comment>ul>li>.detail-right>.content img {
    margin: 10px auto;
    max-width: 700px
}

.com-topic-comment>ul>li>.detail-right>.zuijia {
    background-color: #f5f5f5;
    padding: 0 10px 10px
}

.com-topic-comment>ul>li>.detail-right>.zuijia:before {
    display: block;
    height: 30px;
    margin-bottom: 10px;
    padding-left: 50px;
    background: url("../../assets/files/ba1d1c2af42dc8104387f0cdadb5e151.png") left center no-repeat;
    background-size: auto 100%;
    content: '最佳答案';
    line-height: 30px;
    color: #EE4653
}

.com-topic-comment>ul>li>.detail-right>.quote-wrap {
    min-height: auto;
    margin: 10px 40px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #e9e9e9
}

.com-topic-comment>ul>li>.detail-right>.bottom-bar {
    position: absolute;
    bottom: 0;
    left: 10px;
    right: 10px;
    padding: 10px 0 15px;
    font-size: 12px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1
}

.com-topic-comment>ul>li>.detail-right>.bottom-bar>.tuijian {
    float: left;
    max-width: 400px;
    color: #999;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1
}

.com-topic-comment>ul>li>.detail-right>.bottom-bar>.huifu:hover,
.com-topic-comment>ul>li>.detail-right>.bottom-bar>.tuijian:hover {
    color: #EE4653
}

.com-topic-comment>ul>li>.detail-right>.bottom-bar>.huifu {
    float: right;
    padding: 0 6px;
    cursor: pointer
}

.com-topic-comment>.footer-pager {
    padding: 20px 0;
    text-align: left
}

.com-detail-footer-items>.com-part>.part-title,
.com-detail-footer-items>.com-part>.part-title .more {
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-detail-footer-items>.com-part {
    margin: 40px 0
}

.com-detail-footer-items>.com-part>.part-title {
    line-height: 22px;
    margin-bottom: 10px;
    padding-bottom: 8px;
    font-size: 18px;
    font-weight: 400;
    border-bottom: 1px solid #e9e9e9
}

.com-detail-footer-items>.com-part>.part-title .more {
    float: right;
    margin-right: 10px;
    font-size: 14px;
    color: #999
}

.com-detail-footer-items>.com-part>.part-title .more:hover {
    color: #37B5F8
}

.com-side-com-list-col3>.list-w {
    margin: 0 -20px
}

.com-side-com-list-col3>.list-w>li {
    float: left;
    width: 33.3%;
    margin: 10px 0;
    padding: 0 20px;
    color: #666
}

.com-side-com-list-col3>.list-w>li>a {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.com-side-com-list-col3>.list-w>li>a>.dian {
    padding-right: 5px
}

.common-paging>ul>li.pre {
    background: url("../../assets/files/405fee75f912878a3a64cb3185510e6f.png") center no-repeat
}

.common-paging>ul>li.next {
    background: url("../../assets/files/0d6cea75496cac7ee3a629e5369cb9d7.png") center no-repeat
}

.common-paging>ul>li.disabled.pre {
    background: url("../../assets/files/e564bffffcfb26636113cb226064784e.png") center no-repeat
}

.common-paging>ul>li.disabled.next {
    background: url("../../assets/files/6fcaa9f4e4f732405a58f524004e8e92.png") center no-repeat
}

.detail>.topic-pager>.right>button,
.detail>.topic-title,
.detail>.topic-title>.only-author {
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.detail {
    margin-top: 20px
}

.detail>.topic-pager {
    margin-left: -6px;
    padding-bottom: 10px
}

.detail>.topic-pager>.right>button {
    margin-top: 8px;
    margin-left: 6px;
    width: 80px;
    line-height: 30px;
    background-color: #EE4653;
    color: #fff;
    font-size: 14px
}

.detail>.topic-pager>.right>button+button {
    background-color: #f90
}

.detail>.topic-pager.bottom {
    padding-top: 10px;
    padding-bottom: 0
}

.detail>.topic-title {
    position: relative;
    line-height: 38px;
    padding-left: 20px;
    border: 1px solid #e9e9e9;
    font-size: 16px;
    font-weight: 700
}

.detail>.topic-title>.shoucang {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 42px;
    height: 15px;
    margin-left: 5px;
    background: url("../../assets/files/5630be4dd152aa14fbe2283e975b1ac0.png");
    vertical-align: middle;
    cursor: pointer
}

.detail>.topic-title>.only-author {
    float: right;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400
}

.detail>.topic-title>h1 {
    display: inline-block;
    font-size: inherit;
    font-weight: inherit
}

.detail>.topic-hot {
    padding-top: 30px
}

.detail .content-link {
    color: #7eb4ed
}

.detail .content-link:hover {
    color: #7eb4ed;
    text-decoration: underline
}

.detail .com-jiaxiao-list-random {
    width: auto
}

.detail .com-jiaxiao-list-random>.list-w {
    margin-left: -150px
}

.detail .com-jiaxiao-list-random>.list-w>li {
    padding-left: 150px;
    margin: 0
}

.layout-user {
    height: 34px;
    line-height: 20px;
    padding: 7px 0;
    background: #f5f5f5;
    color: #999
}

.layout-user>.content .left-link>li .tiku-mune ul>li a.active,
.layout-user>.content .left-link>li .tiku-mune ul>li a:hover,
.layout-user>.content .right-link .active,
.layout-user>.content .right-link a:hover {
    color: #37B5F8
}

.layout-user>.content .city {
    padding-left: 15px;
    background: url("../../assets/files/1272afef1a66936bae7d785d24e4b319.png") left center no-repeat;
    cursor: pointer
}

.com-top-user-city .city {
    padding-left: 15px;
    background: url("../../assets/files/eae9b23cf7983349005bb5e7e771478a.png") left center no-repeat;
    cursor: pointer
}

.common-city-selection>.list>ul>li>.item>ul {
    padding-left: 58px
}

.common-city-selection-dialog>.close {
    width: 25px;
    height: 25px;
    background: url("../../assets/files/a7474b44821b980050ffead66d07dd38.png") center no-repeat;
    right: 0;
    top: 0;
    cursor: pointer;
    position: absolute;
    display: block
}

.layout-navigation>.navigation-top>.logo-w .logo {
    display: block;
    width: 207px;
    height: 100%;
    overflow: hidden;
    vertical-align: middle;
    text-align: left;
    background: url("../../assets/files/faab6749bd917811e688ac5d5bc2b782.png") left center no-repeat
}

.layout-navigation>.navigation-top .search-w button {
    position: absolute;
    top: 7px;
    right: 5px;
    height: 29px;
    width: 30px;
    background: url("../../assets/files/988d5f1c9d31781584e82d0ecad4f582.png") center no-repeat;
    border: none
}

.layout-navigation>.navigation-content>.nav-w>li {
    display: inline-block;
    padding: 0;
    vertical-align: top;
    width: 107px;
    text-align: center
}

.layout-navigation2>.logo-w .logo {
    display: block;
    width: 207px;
    height: 100%;
    overflow: hidden;
    vertical-align: middle;
    text-align: left;
    background: url("../../assets/files/5309392905c9eaaccdc170f078756f78.png") left center no-repeat
}

.layout-navigation2>.nav-w .search-w button {
    position: absolute;
    top: 2px;
    right: 5px;
    height: 29px;
    width: 30px;
    background: url("../../assets/files/71073a79ef0afc287a4030ad69dfcdce.png") center no-repeat;
    border: none
}

.layout-footer>.content .footer3 {
    height: 108px;
    width: 1200px;
    padding-top: 25px;
    margin: 0 auto;
    padding-bottom: 18px;
    overflow: hidden
}

.layout-footer>.content .footer3 .left {
    float: left;
    margin-left: 180px;
    padding-right: 70px;
    border-right: 1px solid #666;
    width: 232px;
    height: 108px
}

.layout-footer>.content .footer3 .left a {
    display: block;
    padding-top: 15px;
    height: 56px
}

.layout-footer>.content .footer3 .right {
    float: left;
    height: 108px;
    margin-left: 70px
}

.com-dialog-growUp .growUp {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 6rem;
    height: 6rem;
    border-radius: 8px;
    background: url("../../assets/files/fe1205c93682f15045fb62830fb73483.png") center .34rem no-repeat #fff;
    background-size: 3.72rem
}

.com-enroll-pannel .input-box>form>.row .submit-btn.loading {
    background: url("../../assets/files/2e15855e2d3012e2f442c66c952800a9.gif") center no-repeat;
    background-size: contain;
    cursor: default;
    color: transparent
}

.com-dialog-enroll-freephone .dialog-regist-box>.row-tip {
    margin-top: -5px;
    color: #999;
    text-align: center
}

.com-dialog-login .dialog-login-box>.tab-content-w .tab-content-login .other-login>.type .qq {
    width: 44px;
    height: 44px;
    background: url("../../assets/files/8f8a1b78796d67a65e2de8379c81b4ee.png") center center no-repeat;
    background-size: 44px 44px;
    display: block;
    margin: 0 auto
}

.com-dialog-login .dialog-login-box>.tab-content-w .tab-content-coach>.login-code>.img {
    background: url("../../assets/files/7d633be84261a709f4f307c4e7dab942.png") left top no-repeat;
    background-size: 255px 48px;
    position: absolute;
    left: 0;
    top: 0;
    width: 255px;
    height: 48px;
    z-index: 2
}

.com-dialog-login .dialog-login-box>.tab-content-w .tab-content-coach>.login-code>.code {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 200px;
    height: 200px;
    background: url("../../assets/files/3476971d4d13a3421c9619ec7ce84e5e.png") center center no-repeat;
    background-size: 174px 174px;
    border: 1px solid #eee
}

.com-login-pannel .input-box>form>.row select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url("../../assets/files/4047b1ec9715fd3a9818df3dde9a3cf1.png") right 10px center no-repeat #fff
}

.com-login-pannel .input-box>form>.row .submit-btn.loading {
    background: url("../../assets/files/b0438773813bf9a2eabe20147a3f29e2.gif") center no-repeat;
    background-size: contain;
    cursor: default;
    color: transparent
}

.com-dialog-login-share .dialog-login-box .other-login-type>.qq {
    width: 44px;
    height: 44px;
    background: url("../../assets/files/f4078891d88bdf4b07ebb580f72840c4.png") center center no-repeat;
    background-size: 44px 44px;
    display: block;
    margin: 20px auto 0
}

.com-login-share .loading {
    min-width: 300px;
    min-height: 180px;
    margin: 0 auto;
    background: url("../../assets/files/85f1c93260c1a7335a6e30ce1fe0f5b2.gif") center no-repeat;
    background-size: 100px
}

.com-footer-flex-panel {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 110px;
    z-index: 0
}

.com-footer-flex-panel>.content-box {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    z-index: 3
}

.com-footer-flex-panel>.content-box>.flex-close {
    position: absolute;
    top: 45px;
    right: 100px;
    height: 32px;
    width: 32px;
    line-height: 30px;
    border: none;
    background: url("../../assets/files/620b6e8cf3a4e36deba36f5e8f78fbe5.png") center no-repeat;
    font-size: 32px;
    color: #999;
    text-align: center;
    cursor: pointer
}

.com-footer-flex-panel>.content-box>.bg-img {
    position: absolute;
    top: 0;
    left: 90px;
    width: 160px;
    height: 125px;
    background: url("../../assets/files/11d274126fb4a3ce2876037345e1954d.png") center no-repeat;
    background-size: 100%
}

.baidu-search-box input {
    line-height: 24px;
    padding: 0 20px 0 5px;
    background: url("../../assets/files/9b0522d7a24f31d2702e7baa8b575e94.png") right 5px center no-repeat;
    border-color: #37B5F8
}

.com-appdown-dialog>.dialog-box>.dialog-content>.erwm {
    width: 296px;
    height: 296px;
    margin: 20px auto;
    background: url("../../assets/files/dcd41a46d528d96dc110be54407faf44.png") center no-repeat;
    background-size: 80% auto;
    border: 1px solid #e9e9e9;
    border-radius: 6px
}

.com-appdown-dialog>.dialog-box>.btn-dialog-close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    background: url("../../assets/files/d381506045f2d8582bdf00c0d5b09d45.jpg") center no-repeat;
    background-size: 40%;
    border: 1px solid #e9e9e9;
    border-radius: 50%;
    cursor: pointer
}

.com-dialog-first-visited2>.content-box>.content {
    height: 30px;
    line-height: 30px;
    padding-left: 25px;
    background: url("../../assets/files/c454c61f8db82676e5a7d815ac0458e5.png") left center no-repeat;
    background-size: 22px 15px;
    font-size: 18px
}

.com-dialog-first-visited3 .visited-content-box>.item-left>.row-city .city-name {
    padding-right: 20px;
    background: url("../../assets/files/ac7a65de254ed4b793fec8e915767497.png") right center no-repeat;
    color: #37B5F8;
    cursor: pointer;
    font-size: 14px
}

.com-dialog-first-visited3 .visited-content-box>.item-left>.row-baomin .select-baomin {
    width: 260px;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    margin-left: 0;
    padding: 0 10px;
    border: 1px solid #e9e9e9;
    outline: 0;
    -webkit-appearance: none;
    background: url("../../assets/files/b202869db721d36e059a395366ec278e.png") right 10px center no-repeat #fff
}

.com-dialog-first-visited4 .visited-content-box>.item-left>.row-city .city-name {
    padding-right: 20px;
    background: url("../../assets/files/0e4926aeb52a2955cd85b7faeed2aa33.png") right center no-repeat;
    color: #37B5F8;
    cursor: pointer;
    font-size: 14px
}

.com-dialog-first-visited4 .visited-content-box>.item-left>.row-baomin .select-baomin {
    width: 260px;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
    margin-left: 0;
    padding: 0 10px;
    border: 1px solid #e9e9e9;
    outline: 0;
    -webkit-appearance: none;
    background: url("../../assets/files/026013145c714bb61581f2fd4df84a5d.png") right 10px center no-repeat #fff
}

body,
html {
    overflow-x: hidden
}

.container .none-data-block:before {
    display: block;
    content: '';
    width: 100%;
    height: 2.4rem;
    background: url("../../assets/files/12269a28a995a89f8407725fff30cb36.png") center top no-repeat;
    background-size: 2.8rem
}

.container .page-empty-data span:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background: url("../../assets/files/12269a28a995a89f8407725fff30cb36.png") center 30px no-repeat;
    background-size: 280px
}

.container .no-data {
    margin: 30px;
    background: url("../../assets/files/b12469ee38c879f3d0f59dc3392fe978.png") center top no-repeat;
    background-size: 50px 50px;
    padding-top: 60px;
    text-align: center;
    color: #999
}

.container .star-w-s {
    width: 90px;
    height: 20px;
    margin: 10px 0;
    background: url("../../assets/files/d59d67daf676ffce9e157bd3a0634dee.png") left center no-repeat
}

.container .star-w-s>.bfb {
    height: 100%;
    background: url("../../assets/files/07c954300da670874111fd227ae902fb.png") left center no-repeat
}

.container .star-w-m {
    width: 110px;
    height: 20px;
    margin: 10px 0;
    background: url("../../assets/files/e7754c64750c1cb6b742ea4b10dac05a.png") left center no-repeat
}

.container .star-w-m>.bfb {
    height: 100%;
    background: url("../../assets/files/7c543ae5cb114b37005a31933ba1af4a.png") left center no-repeat
}
</style>
